import { Theme } from 'theme-ui';

const buttons: Theme['buttons'] = {
  primary: {
    py: 2,
    px: 3,
    fontFamily: 'body',
    borderRadius: '38px',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:active': {
      bg: '#E39256',
    },
    '&:disabled,&[disabled]': {
      cursor: 'default',
      bg: 'highlight',
      color: 'muted',
    },
  },
  small: {},
  outline: {
    py: '8px',
    px: 3,
    fontFamily: 'body',
    borderRadius: '38px',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, box-shadow 0.3s',
    bg: 'transparent',
    boxShadow: (theme) => `inset 0 0 0 3px ${theme.colors?.secondary}`,
    // border: '3px solid',
    // borderColor: 'secondary',
    color: 'text',
    '&:active': {
      bg: 'gray2',
    },
    '&:hover': {
      boxShadow: (theme) => `inset 0 0 0 3px ${theme.colors?.secondary}`,
    },
    '&:disabled,&[disabled]': {
      cursor: 'default',
      bg: 'highlight',
      boxShadow: (theme) => `inset 0 0 0 3px ${theme.colors?.muted}`,
      color: 'muted',
    },
  },
  warning: {
    py: '8px',
    px: 3,
    fontFamily: 'body',
    borderRadius: '38px',
    outline: 'none',
    cursor: 'pointer',
    bg: 'transparent',
    transition: 'background-color 0.3s, box-shadow 0.3s',
    boxShadow: 'inset 0 0 0 3px var(--theme-ui-colors-errors, #E03B54)',
    // border: '3px solid',
    // borderColor: 'secondary',
    color: 'error',
    '&:active': {
      bg: 'gray2',
    },
    '&:disabled,&[disabled]': {
      cursor: 'default',
      bg: 'highlight',
      boxShadow: 'inset 0 0 0 3px var(--theme-ui-colors-error, #E03B54)',
      color: 'muted',
    },
  },
  destructive: {
    py: '8px',
    px: 3,
    fontFamily: 'body',
    borderRadius: '38px',
    outline: 'none',
    cursor: 'pointer',
    bg: 'error',
    color: 'background',
    transition: 'background-color 0.3s',
    '&:active': {
      bg: '#CB374D',
    },
    '&:disabled,&[disabled]': {
      cursor: 'default',
      bg: 'highlight',
      color: 'muted',
    },
  },
};

export default buttons;
