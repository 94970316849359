/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'oidc-react';
import { BrowserRouter as Router } from 'react-router-dom';


import App from './App';
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
  onSignIn: () => {
    window.location.hash = '';
  },
  authority: 'https://accounts.google.com',
  clientId:
    '702460706354-h5a7fvf3c2ihb3krhvu0hrq74kk78hi8.apps.googleusercontent.com',
  responseType: 'id_token',
  scope: 'openid profile email',
  redirectUri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/'
      : 'https://console.ayr.no/',
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
        <Router>
          <App />
        </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
