import React, { lazy } from 'react';

const CustomerApp = lazy(() => import('./modules/customer/CustomerApp'));
const SettingsApp = lazy(() => import('./modules/settings/SettingsApp'));

const routes = [
  // { path: '/', element: <Dashboard /> },
  { path: 'customer/*', element: <CustomerApp /> },
  { path: 'settings/*', element: <SettingsApp /> },
  // {
  //   path: 'catalog/*',
  //   children: [
  //     { path: 'products', element: <Products /> },
  //     { path: 'product/:productId/*', element: <Product /> },
  //     { path: 'categories', element: <ProductCategories /> },
  //   ],
  // },
  // {
  //   path: 'order/*',
  //   element: <Orders />,
  //   children: [{ path: ':orderId', element: <Order /> }],
  // },
  // {
  //   path: 'page/*',
  //   children: [
  //     { path: '', element: <Pages /> },
  //     { path: 'create', element: <Page /> },
  //   ],
  // },
  // { path: 'settings/*', element: <Settings /> },
  // { path: '*', element: <NotFound /> },
];

export default routes;
