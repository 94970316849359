import { Theme } from 'theme-ui';
import styles from './styles';
import colors from './colors';
import buttons from './buttons';

const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048],
  fonts: {
    body:
      'Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    '0.71rem', // 0
    '1rem', // 1
    '1.5rem', // 2
    '2rem', // 3
    '3rem', // 4
    '4rem', // 5
    '4.5rem', // 6
    '5rem', // 7
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      mb: 4,
    },
  },
  links: {
    nav: {
      p: 3,
      pb: 0,
    },
  },
  colors,
  styles,
  buttons,
} as Theme;

export default theme;
