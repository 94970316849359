/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Container, Box, Text, IconButton } from 'theme-ui';
import { Global } from '@emotion/core';
import React from 'react';
import Link from './Link';
import Logo from './Logo';
import { MdExitToApp } from 'react-icons/md';
import { useAuth } from 'oidc-react';

const Layout: React.FC = ({ children }) => {
  const auth = useAuth();

  return (
    <Container
      sx={{
        maxWidth: '1024px',
        margin: '0 auto',
        mt: 3,
      }}
    >
      <Global styles={theme => ({
        ':root': {
          '--mdc-theme-primary': `${theme.colors.primary}`
        }
      })} />
      <Flex
        sx={{
          height: '48px',
          alignItems: 'center',
          p: 3,
          // bg: 'primary',
        }}
      >
        <Flex sx={{ alignItems: 'center', flexGrow: 1 }}>
          <Logo sx={{ mr: 2 }} />
          <Text>Console</Text>
        </Flex>
        <Box>
          <IconButton
            onClick={() => {
              auth?.signOut();
            }}
          >
            <MdExitToApp size={24} />
          </IconButton>
        </Box>
      </Flex>
      <Flex sx={{ mt: 3 }}>
        <Flex
          as="nav"
          sx={{
            flexDirection: 'column',
            width: '258px',
          }}
        >
          <Link to="customer">Kunder</Link>
          <Link to="settings">Innstillinger</Link>
        </Flex>
        <Box
          sx={{
            p: 3,
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Flex>
    </Container>
  );
};

export default Layout;
