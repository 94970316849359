import React from 'react';
import { Box, BoxProps } from 'theme-ui';

export interface LogoProps extends BoxProps {
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ height = 30, ...props }) => (
  <Box
    as="svg"
    // @ts-expect-error
    xmlns="http://www.w3.org/2000/svg"
    height={height + ''}
    viewBox="0 0 60 33.248"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h60v33.248H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path d="M51.289 19.536a9.762 9.762 0 006.193-3.462 9.633 9.633 0 002.023-5.98 9.146 9.146 0 00-3.035-7.048Q53.435.264 47.838.27h-8.9L28.055 26.025 18.827.225h-6.283L0 33.249h6l9.667-26.482 5.227 14.994-6.958-.056-1.54 4.811h10.15l2.372 6.733h5.688l12.8-28.426 4.283.011a6.4 6.4 0 014.608 1.416 4.853 4.853 0 011.63 3.81 5.446 5.446 0 01-1.517 4.1 6.41 6.41 0 01-4.608 1.472h-5.26l10.858 17.6H60z" />
      <path d="M32.787 5.89L30.674 0h-6.126l5.193 13.016z" />
    </g>
  </Box>
);

export default Logo;
