/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Box, NavLinkProps } from 'theme-ui';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export interface LinkProps extends RouterLinkProps, NavLinkProps {}

const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
  <Box
    as={RouterLink}
    ref={ref}
    variant="nav"
    {...props}
    __css={{
      color: 'inherit',
      textDecoration: 'none',
      fontWeight: 'bold',
      display: 'inline-block',
      '&:hover, &:focus, &.active': {
        color: 'primary',
      },
    }}
    __themeKey="links"
  />
));

export default Link;
