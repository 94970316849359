import { useAuth } from 'oidc-react';
import React, { Suspense } from 'react';
import { Box, Button, Heading, ThemeProvider } from 'theme-ui';

import { useRoutes } from 'react-router-dom';
import Layout from './components/Layout';
import { ApolloProvider } from './providers/ApolloProvider';

import theme from './theme';
import routes from './routes';
import Logo from './components/Logo';
import Loading from './components/Loading';

function App() {
  const auth = useAuth();
  const router = useRoutes(routes);

  if (auth && auth.userData) {
    return (
      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <Layout>
            <Suspense fallback={<Loading />}>{router}</Suspense>
          </Layout>
        </ThemeProvider>
      </ApolloProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          margin: 'auto',
          width: '300px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Logo />
        <Heading sx={{ pt: 3 }}>Du er logget ut</Heading>
        <Button onClick={() => auth?.signIn()}>Logg på</Button>
      </Box>
    </ThemeProvider>
  );
}

export default App;
